import {useNavigation} from '@react-navigation/native';
import {loadStripe} from '@stripe/stripe-js';
import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
import {Text} from 'react-native';

import EtherButton from './EtherButton';
import {STATUS} from '../utils/common/constants';
import {isProd} from '../utils/common/funcs';
import {createStripeSession} from '../utils/common/orders';
import etherFetch from '../utils/ether-fetch/etherFetch';

const PROD_KEY =
  'pk_live_51IUxZgGbRqCaF121oFdRh6jm5dar9uCyCs2VjLrEanRsudoK6iOmfZZpBzAISO1aLNEBCyGmCV1GLNBFWb8jwShz004dRsg55I';
const TEST_KEY =
  'pk_test_51IUxZgGbRqCaF121QGC5J8Z9LAXQctj8bArbsgnpgT5ajY2ywZt4WTeLP61e2C7YOJt8KjyHcIkKkwgNnyjgevbr00DzxSjDz0';

const stripePromise = loadStripe(isProd() ? PROD_KEY : TEST_KEY);

async function isPurchased(cartId) {
  try {
    const res = await etherFetch('/order/is-purchased', {
      method: 'POST',
      body: {
        cartId,
      },
    });
    const data = await res.json();
    return data.purchased;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export default function StripeCheckout({
  cart,
  style: overrides,
  textStyle,
  buttonText,
  ...rest
}) {
  const {navigate} = useNavigation();
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const orderName = cart.items.map((item) => item.pack.name).join(', ');

  async function requestStripe() {
    setStatus(STATUS.BUSY);

    try {
      const purchased = await isPurchased(cart._id);
      if (purchased) {
        navigate('Download', {cart: cart._id});
        return;
      }
    } catch (err) {
      console.error(err);
      return;
    }

    try {
      const stripe = await stripePromise;
      const session = await createStripeSession(orderName, cart._id);
      if (session) {
        await stripe.redirectToCheckout({
          sessionId: session.id,
        });
      }
      setStatus(STATUS.INVALID);
    } catch (err) {
      toast.error(`Error creating checkout session:\n${err?.message}`);
    }

    setStatus(STATUS.SUCCESS);
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }

    if (query.get('canceled')) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready.",
      );
    }
  }, []);

  return message ? (
    <section>
      <p>{message}</p>
    </section>
  ) : (
    <EtherButton
      style={overrides}
      onPress={requestStripe}
      status={status}
      {...rest}
    >
      <Text style={textStyle}>{buttonText}</Text>
    </EtherButton>
  );
}
