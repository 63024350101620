import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text} from 'react-native';

import EtherDropdown from './EtherDropdown';
import EtherInput from './EtherInput';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';
import {validateEmail} from '../utils/common/inputValidation';
import etherFetch from '../utils/ether-fetch/etherFetch';

export default function MailingList() {
  const roleOptions = [
    {
      value: 'Photographer / Photo Agency',
      label: 'Photographer / Photo Agency',
    },
    {value: 'Business Client', label: 'Business Client'},
    {value: 'Event Organizer', label: 'Event Organizer'},
    {value: 'Individual Buyer', label: 'Individual Buyer'},
    {value: 'Other', label: 'Other'},
  ];
  const [roles, setRoles] = useState([]);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(STATUS.IDLE);
  const {style} = useTheme(getThemedStyles);

  async function onSubmit() {
    setStatus(STATUS.BUSY);
    const validEmail = validateEmail(email);
    if (!validEmail) {
      toast.error('Please enter a valid email');
      setStatus(STATUS.IDLE);
      return;
    }
    const rolesArray = roles.map((role) => role.value);
    return etherFetch('/messaging/mailing-list', {
      method: 'POST',
      body: {
        email,
        roles: rolesArray,
      },
    })
      .then(() => {
        toast.success('You have been added to our mailing list');
        setEmail('');
        setRoles([]);
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again');
      })
      .finally(() => {
        setStatus(STATUS.IDLE);
      });
  }

  return (
    <View style={style.container}>
      <View style={style.innerContainer}>
        <Text style={style.headerText}>Join Our Mailing List!</Text>
        <Text style={style.subHeaderText}>
          Subscribe to our mailing list to receive occasional updates about
          EtherMedia. We promise not to spam you :)
        </Text>
        <View style={style.form}>
          <EtherDropdown
            placeholder="Who are you?"
            style={style.dropdown}
            selected={roles}
            setSelected={setRoles}
            options={roleOptions}
          />
          <EtherInput
            value={email}
            onChangeText={setEmail}
            placeholder="Email"
            btnText="Join"
            onPress={onSubmit}
            onSubmitEditing={onSubmit}
            status={status}
          />
        </View>
        <Text style={style.legalText}>
          By joining our mailing list you agree to receive emails from us. You
          can unsubscribe at any time.
        </Text>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => {
  return {
    container: {
      backgroundColor: theme.SECOND,
      justifyContent: 'center',
      width: '100%',
      paddingVertical: 50,
      paddingBottom: 180,
      flexDirection: 'column',
      mobile: {
        alignItems: 'center',
        padding: 20,
        flexDirection: 'column',
      },
    },
    dropdown: {
      marginRight: 20,
      mobile: {
        marginRight: 0,
        marginBottom: 10,
      },
    },
    form: {
      zIndex: 9999,
      width: '100%',
      maxWidth: 200,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      mobile: {
        flexDirection: 'column',
        maxWidth: 260,
      },
    },
    innerContainer: {
      width: '100%',
      maxWidth: 600,
      minWidth: 150,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      mobile: {
        paddingHorizontal: 20,
      },
    },
    headerText: {
      fontFamily: 'NotoSans_Regular',
      fontSize: fontSize.starHeader,
      color: theme.LIGHT,
      marginBottom: 20,
      textAlign: 'center',
      mobile: {
        marginBottom: 20,
        fontSize: fontSize.planetHeader,
        textAlign: 'center',
      },
    },
    legalText: {
      fontFamily: 'NotoSans_Bold',
      fontSize: fontSize.legal,
      color: theme.DARK,
      textAlign: 'center',
      marginTop: 10,
      maxWidth: 410,
      mobile: {
        maxWidth: 200,
      },
    },
    subHeaderText: {
      fontFamily: 'NotoSans_Regular',
      fontSize: fontSize.body,
      color: theme.LIGHT,
      marginBottom: 40,
      textAlign: 'center',
      mobile: {
        marginBottom: 20,
        fontSize: fontSize.body,
        textAlign: 'center',
      },
    },
  };
};
