import {func, string, bool, node} from 'prop-types';
import React, {useState} from 'react';
import {Text, View, TextInput} from 'react-native';

import FaIcon from '../components/FaIcon';
import {useTheme} from '../context/ThemeContext';

OrderInput.propTypes = {
  onChange: func.isRequired,
  title: node.isRequired,
  placeholder: string,
  defaultValue: string,
  secureTextEntry: bool,
  value: string,
};

export default function OrderInput({
  style: overrides,
  inputStyle,
  onChange,
  icon,
  title,
  placeholder,
  defaultValue,
  secureTextEntry,
  keyboardType,
  value = null,
  ...rest
}) {
  const [focus, setFocus] = useState(false);
  const {style, values} = useTheme(getThemedStyles);

  return (
    <View style={[style.container, overrides]}>
      <FaIcon
        icon={icon}
        size={32}
        color={values.SECOND}
        style={style.iconContainer}
      />
      <View>
        <Text style={style.text}>{title}</Text>
        <TextInput
          onChangeText={onChange}
          placeholder={placeholder}
          style={[
            focus ? style.settingsInputOnFocus : style.settingsInputOnBlur,
            inputStyle,
          ]}
          autoCorrect={false}
          autoCapitalize="none"
          value={value || defaultValue}
          secureTextEntry={secureTextEntry}
          keyboardType={keyboardType}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          {...rest}
        />
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  settingsInputOnBlur: {
    height: 45,
    width: 324,
    backgroundColor: theme.BGSECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 2,
    paddingLeft: 15,
    margin: 5,
    marginTop: 5,
  },
  settingsInputOnFocus: {
    height: 45,
    width: 324,
    backgroundColor: theme.BGSECOND,
    borderColor: theme.FIRST,
    borderWidth: 2,
    borderRadius: 5,
    padding: 2,
    paddingLeft: 15,
    margin: 5,
    marginTop: 5,
  },
  text: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.subheader,
    color: theme.SECOND,
    textAlign: 'left',
    marginLeft: 5,
    marginTop: 3,
    textTransform: 'uppercase',
  },
});
