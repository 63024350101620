import {faCopy, faRefresh} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text} from 'react-native';

import EtherButton from './EtherButton';
import FaIcon from './FaIcon';
import {useTheme} from '../context/ThemeContext';
import {STATUS} from '../utils/common/constants';

function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(
    () => {
      toast.success('Copied to clipboard');
    },
    () => {
      toast.error('Copy to clipboard failed');
    },
  );
}

export default function EtherCopyButton({
  buttonText,
  copyText,
  onPress = () => Promise.resolve(),
}) {
  const {style, values} = useTheme(getThemedStyles);
  const [loadStatus, setLoadStatus] = useState(STATUS.IDLE);

  async function handlePress() {
    setLoadStatus(STATUS.BUSY);
    try {
      await onPress();
    } finally {
      setLoadStatus(STATUS.IDLE);
    }
  }

  if (copyText) {
    return (
      <View style={style.copyBox}>
        <Text style={style.text}>{copyText}</Text>
        <View style={style.controls}>
          <EtherButton
            basic
            style={style.controlItem}
            onPress={() => copyToClipboard(copyText)}
          >
            <FaIcon icon={faCopy} color={values.DARK} size={20} />
          </EtherButton>
          <EtherButton basic style={style.controlItem} onPress={handlePress}>
            <FaIcon icon={faRefresh} color={values.DARK} size={20} />
          </EtherButton>
        </View>
      </View>
    );
  }
  return (
    <EtherButton text={buttonText} onPress={handlePress} status={loadStatus} />
  );
}

const getThemedStyles = (theme, fontSize) => ({
  controlItem: {
    marginRight: 6,
  },
  controls: {
    position: 'absolute',
    flexDirection: 'row',
    right: 0,
  },
  copyBox: {
    backgroundColor: theme.LIGHT,
    borderColor: theme.FIRST,
    borderWidth: 2,
    height: 45,
    paddingHorizontal: 15,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    color: theme.DARK,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
  },
});
