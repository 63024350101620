import {faTrash} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {View, FlatList, Text, TouchableOpacity} from 'react-native';

import FaIcon from './FaIcon';
import {useCart} from '../context/CartContext';
import {useTheme} from '../context/ThemeContext';
import {ellipsify} from '../utils/common/funcs';

function formatCents(cents) {
  const dollars = (cents / 100).toFixed(2);
  return dollars;
}

function calculateSubtotal(lineItems) {
  let subtotal = 0;
  lineItems.forEach((object) => {
    subtotal = subtotal + object.price;
  });
  return formatCents(subtotal);
}

export default function Payment() {
  const {style} = useTheme(getThemedStyles);
  const {cart, removeItem} = useCart();

  return (
    <View style={style.main}>
      <View style={style.header}>
        <Text style={style.headerText}> Item</Text>
        <Text style={style.headerText}>Price</Text>
      </View>
      <FlatList
        data={cart}
        style={{bottom: 2}}
        renderItem={({item}) => (
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity
              style={style.remove}
              onPress={() => removeItem(item)}
            >
              <FaIcon icon={faTrash} size={20} style={style.removeButton} />
            </TouchableOpacity>
            <View style={style.row}>
              <Text style={style.packText}>{ellipsify(item.name, 20)}</Text>
              <Text style={style.priceText}>${formatCents(item.price)}</Text>
            </View>
          </View>
        )}
        numColumns={1}
        keyExtractor={(item) => item.name}
      />
      <View style={style.subTotal}>
        <Text style={style.subTotalText}>Subtotal:</Text>
        <Text style={style.subTotalText}>${calculateSubtotal(cart)}</Text>
      </View>
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 40,
    paddingHorizontal: 10,
  },
  headerText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.FIRST,
    marginLeft: 10,
  },
  main: {
    flex: 1,
    justifyContent: 'center',
    padding: 5,
    paddingHorizontal: 15,
  },
  packText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    paddingVertical: 20,
  },
  priceText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Regular',
    paddingVertical: 20,
  },
  remove: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
  },
  removeButton: {
    color: 'gray',
  },
  rightPanel: {
    flexDirection: 'row',
  },
  row: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  subTotal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  subTotalText: {
    fontSize: fontSize.subheader,
    fontFamily: 'NotoSans_Bold',
    color: theme.FIRST,
    alignSelf: 'flex-end',
  },
});
