import {faLock} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {View, Text, Image, FlatList} from 'react-native';

import LoadingStore from './LoadingStore';
import FlyerNotFound from '../../assets/flyer_not_found.png';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import PoweredBy from '../../components/PoweredBy';
import {useTheme} from '../../context/ThemeContext';
import {ellipsify, formatTimestamp} from '../../utils/common/funcs';
import {getSafeEvents} from '../../utils/common/stores';

function Banner({item, navigation, storeSlug}) {
  const {style} = useTheme(getThemedStyles);
  const name = item.name;
  const flyer = item.flyer?.url;
  const sample = item.sample?.url;
  const location = item.location;
  const createdAt = item.createdAt;

  return (
    <EtherButton
      basic
      style={style.banner}
      onPress={() => {
        navigation.navigate('PackStorefront', {
          storeSlug,
          eventId: item.id,
        });
      }}
    >
      <View style={style.bannerInfo}>
        <Text style={style.bannerName}>{ellipsify(name, 75)}</Text>
        <Text style={style.bannerText}>
          {formatTimestamp(createdAt)} {location ? `- ${location}` : ''}
        </Text>
      </View>
      {item.requiresAccessCode ? (
        <View style={style.lockContainer}>
          <FaIcon icon={faLock} size={32} style={style.lock} />
          <Text style={style.lockText}>
            Password
            <br />
            Required
          </Text>
        </View>
      ) : null}
      <View style={style.bannerImageContainer}>
        <View style={style.bannerOverlay} />
        <Image
          source={flyer || sample || FlyerNotFound}
          style={style.bannerImage}
        />
      </View>
    </EtherButton>
  );
}

export default function StoreEventView({route, navigation}) {
  const {style} = useTheme(getThemedStyles);
  const [events, setEvents] = useState(null);
  const [storeInfo, setStoreInfo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const {storeSlug} = route.params;

  const activeEvents = (events || [])?.filter((event) => event.active);

  useEffect(() => {
    async function fetchEvents() {
      if (!storeSlug) return;
      setLoaded(false);
      try {
        const {events: fetchedEvents, store} = await getSafeEvents(storeSlug);
        setStoreInfo(store);
        setEvents(fetchedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoaded(true);
      }
    }

    fetchEvents();
  }, [storeSlug]);

  if (!loaded) {
    return <LoadingStore />;
  }
  return (
    <View style={style.container}>
      {storeInfo.logo ? (
        <Image
          style={style.userLogo}
          resizeMode="contain"
          source={storeInfo.logo}
        />
      ) : null}
      <Text style={style.subheader}>{storeInfo.orgname}</Text>
      <Text style={style.header}>
        {storeInfo.welcomeText || `Welcome to ${storeInfo.orgname}'s store!`}
      </Text>
      <View style={style.eventContainer}>
        {activeEvents ? (
          <FlatList
            style={{width: '100%'}}
            contentContainerStyle={{justifyContent: 'center'}}
            data={activeEvents}
            renderItem={({item}) => (
              <Banner
                navigation={navigation}
                item={item}
                storeSlug={storeSlug}
              />
            )}
            keyExtractor={(item) => item.id.toString()}
          />
        ) : (
          <Text>No event data available.</Text>
        )}
      </View>
      <PoweredBy />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  banner: {
    margin: 20,
    marginHorizontal: 'auto',
    width: '100%',
    minWidth: 250,
    maxWidth: 1400,
    height: 380,
    borderRadius: 10,
    marginBottom: 40,
    overflow: 'hidden',
    mobile: {
      height: 240,
    },
  },
  bannerIcon: {
    marginRight: 10,
  },
  bannerImage: {
    zIndex: 0,
    flex: 1,
  },
  bannerImageContainer: {
    position: 'relative',
    flex: 1,
  },
  bannerInfo: {
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    zIndex: 1,
    top: '50%',
    transform: [{translateY: '-50%'}],
  },
  bannerName: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.planetHeader,
    color: theme.LIGHT,
    textAlign: 'center',
    marginHorizontal: 30,
    mobile: {
      fontSize: fontSize.bigBody,
    },
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1,
  },
  bannerText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.header,
    color: theme.LIGHT,
    mobile: {
      fontSize: fontSize.legal,
    },
  },
  buyButton: {
    marginTop: 20,
    marginBottom: 40,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
  },
  empty: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  eventContainer: {
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 40,
    marginBottom: 40,
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.planetHeader,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.header,
    },
  },
  image: {
    flex: 1,
    aspectRatio: 1,
    margin: 2,
  },
  lock: {
    color: theme.LIGHT,
    marginTop: 20,
  },
  lockContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    zIndex: 9999,
    alignItems: 'center',
  },
  lockText: {
    fontFamily: 'NotoSans_Regular',
    textAlign: 'center',
    fontSize: fontSize.tiny,
    color: theme.LIGHT,
    marginTop: 5,
    mobile: {
      color: 'rgba(0, 0, 0, 0)',
      height: 0,
    },
  },
  subheader: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.header,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.bigBody,
    },
  },
  topRightInfo: {
    position: 'absolute',
    top: 10,
    right: 0,
    zIndex: 9999,
    padding: 5,
    paddingHorizontal: 15,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  userLogo: {
    margin: 20,
    minWidth: 300,
    maxWidth: 600,
    height: 140,
  },
});
