import React, {useState} from 'react';
import {View, Text, FlatList} from 'react-native';

import EtherButton from './EtherButton';
import {useCart} from '../context/CartContext';
import {useTheme} from '../context/ThemeContext';
import {centsToDollars, ellipsify} from '../utils/common/funcs';
import {useIsMobile} from '../utils/common/hooks';

function CartItem({item, onRemove}) {
  const {style} = useTheme(getThemedStyles);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <View
      style={style.item}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={isHovered ? 'reveal-remove' : 'remove'}
        style={style.remove}
        onClick={onRemove}
      >
        <p
          className={isHovered ? 'reveal-remove-text' : 'remove-text'}
          style={style.removeText}
        >
          click to remove
        </p>
      </div>
      <Text style={style.itemText}>{ellipsify(item.name, 32)}</Text>
      <Text style={style.itemText}>{centsToDollars(item.price)}</Text>
    </View>
  );
}

function CarouselItem({item}) {
  const {style} = useTheme(getThemedStyles);

  const favoriteAsset = item.assets.find((asset) => asset.favorite);

  const imageUrl = favoriteAsset
    ? favoriteAsset.thumb.url
    : item.assets[0].thumb.url;

  return (
    <View style={style.carouselItem}>
      <img src={imageUrl} style={style.carouselImage} />
      <Text style={style.itemText}>{ellipsify(item.name, 23)}</Text>
    </View>
  );
}

function EmptyCartMessage() {
  const {style} = useTheme(getThemedStyles);
  return (
    <View>
      <Text style={[style.emptyMessage, {marginBottom: 10}]}>
        Your cart is empty.
      </Text>
      <Text style={style.emptyMessage}>
        Please add items to your cart to continue with your purchase.
      </Text>
    </View>
  );
}

function calculateSubtotal(lineItems) {
  let subtotal = 0;
  if (lineItems) {
    lineItems.forEach((obj) => {
      subtotal = subtotal + obj.price;
    });
    return centsToDollars(subtotal);
  }
}

export default function StorefrontCart({open, headerHeight, route}) {
  const {style} = useTheme(getThemedStyles);
  const {cart, removeItem, cartUrl} = useCart();
  const {isMobile} = useIsMobile();

  return (
    <div
      style={{...style.container, marginTop: headerHeight}}
      className={open ? 'open-store-cart' : 'close-store-cart'}
    >
      <View style={style.leftPanel}>
        <FlatList
          horizontal
          data={cart}
          style={{paddingVertical: 20}}
          renderItem={({item}) => <CarouselItem item={item} />}
          keyExtractor={(item) => item.name}
        />
      </View>
      <View style={[style.rightPanel, isMobile ? {minWidth: '100%'} : null]}>
        <View style={style.items}>
          <FlatList
            data={cart}
            style={{paddingVertical: 20, paddingHorizontal: 30}}
            renderItem={({item}) => (
              <CartItem item={item} onRemove={() => removeItem(item)} />
            )}
            keyExtractor={(item) => item.name}
            ListEmptyComponent={EmptyCartMessage}
          />
        </View>
        <View style={style.controls}>
          <View style={style.total}>
            <Text style={style.bigText}>Total: {calculateSubtotal(cart)}</Text>
            <Text style={style.subText}>before tax</Text>
          </View>
          <EtherButton
            testID="button-checkout"
            onPress={() => (window.location.href = cartUrl)}
            text="Checkout"
            style={{height: 35}}
          />
        </View>
      </View>
    </div>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  bigText: {
    fontSize: fontSize.bigBody,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  carouselImage: {
    width: 160,
    height: 160,
    maxWidth: 160,
    maxHeight: 160,
    marginBottom: 10,
    display: 'block',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  carouselItem: {
    width: 160,
    height: '100%',
    marginHorizontal: 20,
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'row',
    backgroundColor: theme.BGFIRST,
  },
  controls: {
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    height: 60,
    marginBottom: 10,
  },
  emptyMessage: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
  },
  hideCart: {
    height: 35,
    backgroundColor: theme.BGSECOND,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
    marginBottom: 5,
  },

  items: {
    flex: 3,
    backgroundColor: theme.BGSECOND,
    borderBottomWidth: 2,
    borderColor: theme.DARK,
  },
  itemText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Bold',
    color: theme.DARK,
  },
  leftPanel: {
    flex: 3,
    height: '100%',
  },
  pack: {
    width: 180,
    height: 180,
  },
  remove: {
    position: 'absolute',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: 22,
    // backgroundColor: theme.RED,
  },
  removeText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  rightPanel: {
    minWidth: 320,
    borderLeftWidth: 2,
    borderColor: theme.DARK,
    flexDirection: 'column',
    height: '100%',
    flex: 1,
  },
  subText: {
    marginTop: -5,
    fontSize: 10,
    fontFamily: 'NotoSans_Regular',
    color: theme.FIRST,
    letterSpacing: 1,
  },
  total: {
    justifyContent: 'center',
    paddingVertical: 0,
  },
});
