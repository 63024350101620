import etherFetch from '../ether-fetch/etherFetch';

const BASE_ROUTE = '/messaging';

/**
 * Returns a promise for sending a cartUrl through text message
 * @param {string<cartUrl>} cartUrl - Link to related checkout
 * @param {string<recipient>} recipient - Phone number of recipient
 * @param {string<name>} name - Name of recipient
 */
export function sendCartText(cartUrl, recipient, name) {
  return new Promise((reject) => {
    etherFetch([BASE_ROUTE, 'sendcarttext'].join('/'), {
      body: {cartUrl, recipient, name},
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => console.log(res))
      .catch((err) => reject(err));
  });
}
