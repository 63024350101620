import {
  faCaretDown,
  faDownload,
  faExternalLinkAlt,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import {number, string, shape} from 'prop-types';
import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, Pressable} from 'react-native';
import {ActivityIndicator} from 'react-native-web';

import {useTheme} from '../../context/ThemeContext';
import {STATUS} from '../../utils/common/constants';
import {downloadItem} from '../../utils/common/download';
import {
  centsToDollars,
  ellipsify,
  formatDate,
  formatTime,
} from '../../utils/common/funcs';
import {getOrderDetails} from '../../utils/common/orders';
import {getPacksSummary} from '../../utils/common/packs';
import EtherButton from '../EtherButton';
import FaIcon from '../FaIcon';

OrderMobile.proptypes = {
  orderData: shape({
    date: string.isRequired,
    name: string.isRequired,
    orderNumber: string.isRequired,
    total: number.isRequired,
  }),
};
export default function OrderMobile({orderData}) {
  const {style, values} = useTheme(getThemedStyles);
  const [downloading, setDownloading] = useState(false);
  const [hydratedOrder, setHydratedOrder] = useState(null);
  const [stripeCharge, setStripeCharge] = useState(null);
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [packList, setPackList] = useState([]);

  function startDownload() {
    if (downloading) return;
    setDownloading(true);
    downloadItem(`/order/download/${orderData.orderNumber}`).then(() => {
      setDownloading(false);
    });
  }

  function getDetails() {
    setOpen(!open);

    if (!hydratedOrder && !stripeCharge) {
      getOrderDetails(orderData.orderNumber)
        .then((orderDetails) => {
          setHydratedOrder(orderDetails.hydratedOrder);
          setPackList(
            orderDetails.hydratedOrder.items.map((item) => item.pack.name),
          );
          setStripeCharge(orderDetails.payment_intent.charges.data[0]);
        })
        .then(() => setLoaded(true))
        .catch((err) => console.error(err));
    }
  }

  function shareReceipt() {
    if (navigator.canShare) {
      navigator
        .share({
          title: 'Stripe Receipt',
          url: `${stripeCharge.receipt_url}`,
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      toast.error('Share is not supported in this browser');
    }
  }

  return (
    <Pressable activeOpacity={0.8} onPress={getDetails}>
      <View style={style.list}>
        <View style={style.header}>
          <Text style={style.dateText}>{formatDate(orderData.purchased)}</Text>
        </View>
        <View style={style.footer}>
          <View style={style.itemBody}>
            <View style={style.leftBlock}>
              <Text style={style.boldText}>
                {formatTime(orderData.purchased)}
              </Text>
            </View>
            <View style={style.rightBlock}>
              <View style={style.eventAndPacks}>
                <Text style={style.boldText}>
                  {ellipsify(orderData.eventName, 24)}
                </Text>
                <Text style={style.packSummary}>
                  {getPacksSummary(orderData, 1)}
                </Text>
                <Text style={style.text}>
                  {centsToDollars(orderData.total)}
                </Text>
              </View>
              <View>
                <EtherButton
                  status={downloading ? STATUS.BUSY : STATUS.IDLE}
                  onPress={startDownload}
                  spinnerSize={24}
                  style={style.download}
                >
                  <FaIcon icon={faDownload} color={values.FIRST} size={24} />
                </EtherButton>
              </View>
            </View>
          </View>
          <View style={style.orderNumberTextContainer}>
            <Text
              style={style.orderNumberText}
            >{`ID: ${orderData.orderNumber}`}</Text>
            <View style={style.expandCollapse}>
              <Text>Tap {open ? 'to hide ' : 'for more'}</Text>
              <FaIcon
                style={style.caret}
                className={open ? 'flip-vertical' : ''}
                size={18}
                icon={faCaretDown}
              />
            </View>
          </View>
        </View>
      </View>
      <div className={open ? 'open-mobile' : 'close'}>
        {loaded ? (
          <View style={[style.drawer, {overflowY: open ? 'auto' : 'hidden'}]}>
            <View style={style.drawerContainer}>
              <View style={style.leftDrawer}>
                <View style={style.drawerCell}>
                  <Text style={style.boldText}>Cart Created</Text>
                  <Text style={style.text}>{formatDate(orderData.date)}</Text>
                  <Text style={style.text}>{formatTime(orderData.date)}</Text>
                </View>
                <View style={style.drawerCell}>
                  <Text style={style.boldText}>Packs</Text>
                  {packList.map((name, index) => (
                    <Text style={style.text} key={index}>
                      {ellipsify(name, 19)}
                    </Text>
                  ))}
                </View>
              </View>
              <View style={style.rightDrawer}>
                <View style={style.drawerCell}>
                  <Text style={style.boldText}>Customer</Text>
                  <Text style={style.text}>
                    {ellipsify(stripeCharge.billing_details.name, 24)}
                  </Text>
                  <a
                    style={style.text}
                    href={`mailto:${stripeCharge.billing_details.email}`}
                  >
                    {stripeCharge.billing_details.email}
                  </a>
                  <a
                    style={style.text}
                    href={`tel:${stripeCharge.billing_details.phone}`}
                  >
                    {stripeCharge.billing_details.phone}
                  </a>
                </View>
                <View style={style.drawerCell}>
                  <Text style={style.boldText}>Seller</Text>
                  <Text style={style.text}>
                    {ellipsify(hydratedOrder.username, 24)}
                  </Text>
                </View>
                <View style={style.drawerCell}>
                  <Text style={style.boldText}>Receipt</Text>
                  <View style={style.buttons}>
                    <EtherButton style={style.receipt} onPress={shareReceipt}>
                      <FaIcon size={18} icon={faShareAlt} />
                    </EtherButton>
                    <EtherButton
                      style={style.receipt}
                      onPress={() => window.open(stripeCharge.receipt_url)}
                    >
                      <FaIcon size={18} icon={faExternalLinkAlt} />
                    </EtherButton>
                  </View>
                </View>
              </View>
            </View>
          </View>
        ) : (
          <ActivityIndicator style={{padding: 40}} />
        )}
      </div>
    </Pressable>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  boldText: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: 15,
    color: theme.DARK,
  },
  buttons: {
    minWidth: 90,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  caret: {
    transition: 'transform ease 0.4s',
  },
  dateText: {
    alignSelf: 'flex-start',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.LIGHT,
  },
  download: {
    height: 44,
    width: 44,
    borderRadius: 10,
    borderColor: theme.FIRST,
    backgroundColor: theme.BGSECOND,
    borderWidth: 2,
  },
  drawer: {
    maxHeight: '100%',
  },
  drawerCell: {
    padding: 4,
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 10,
  },
  drawerContainer: {
    flexDirection: 'row',
  },
  eventAndPacks: {
    flex: 1,
    alignItems: 'center',
  },
  expandCollapse: {
    flexDirection: 'row',
    fontFamily: 'NotoSans_Bold',
    marginRight: 10,
    fontSize: 12,
    color: theme.DARK,
  },
  footer: {
    borderColor: theme.FIRST,
    borderBottomWidth: 2,
  },
  header: {
    width: '100%',
    height: 32,
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  itemBody: {
    width: '100%',
    height: 70,
    flexDirection: 'row',
    backgroundColor: theme.BGSECOND,
    justifyContent: 'center',
  },
  leftBlock: {
    flex: 1,
    backgroundColor: theme.BGSECOND,
    alignItems: 'center',
    justifyContent: 'center',
    borderRightWidth: 2,
    borderColor: theme.FIRST,
    padding: 8,
  },
  leftDrawer: {
    flex: 1,
    padding: 8,
    paddingRight: 9,
    borderRightWidth: 2,
    borderColor: theme.FIRST,
  },
  list: {
    flexDirection: 'column',
  },
  orderNumberText: {
    fontFamily: 'NotoSans_Regular',
    marginLeft: 10,
    fontSize: 12,
    color: theme.DARK,
  },
  orderNumberTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 26,
    alignItems: 'center',
    borderTopWidth: 2,
    borderColor: theme.FIRST,
    backgroundColor: theme.BGSECOND,
  },
  packName: {
    width: '100%',
  },
  packSummary: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    color: theme.DARK,
  },
  receipt: {
    marginTop: 10,
    height: 40,
    width: 40,
    backgroundColor: theme.BGSECOND,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightBlock: {
    flex: 2,
    flexDirection: 'row',
    backgroundColor: theme.BGSECOND,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
  },
  rightDrawer: {
    flex: 2,
    padding: 8,
  },
  text: {
    alignSelf: 'center',
    fontFamily: 'NotoSans_Regular',
    fontSize: 13,
    textAlign: 'center',
    color: theme.DARK,
  },
});
