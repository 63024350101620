import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';

export const blueprint = {
  placeholders: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Your Phone Number',
    validEmail: false,
    validPhone: false,
  },
  contactMethod: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneConsent: false,
  validEmail: false,
  validPhone: false,
};

export default function reducer(state, action) {
  const draft = { ...state };

  switch (action.type) {
    case 'set.firstName': {
      draft.firstName = action.value;
      return draft;
    }
    case 'set.lastName': {
      draft.lastName = action.value;
      return draft;
    }
    case 'set.email': {
      const validateEmail =
        /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
      draft.email = action.value;
      draft.validEmail = validateEmail.test(action.value);
      return draft;
    }
    case 'set.phoneNumber': {
      const phone = state.phoneNumber;
      const newPhone = action.value;

      /*fix libphonenumber-js bug where backspacing a phone field
       *with string '(555)' would flash '(555' then go back to '(555)'
       */
      const removeParenthesis =
        phone.slice(-1) === ')' && newPhone.length < phone.length;

      const input = removeParenthesis ? newPhone.slice(0, 3) : action.value;
      const formattedPhone = new AsYouType('US').input(input);
      draft.phoneNumber = formattedPhone;
      const parsedNumber =
        !!input && parsePhoneNumberFromString(`+1 ${formattedPhone}`);
      draft.validPhone = parsedNumber && parsedNumber.isValid();
      return draft;
    }
    case 'toggle.consent': {
      draft.phoneConsent = !state.phoneConsent;
      return draft;
    }
    case 'set.contactMethod': {
      draft.contactMethod = action.value;
      return draft;
    }
    default:
      throw new Error('Unhandled settings reducer case');
  }
}
