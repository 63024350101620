import {string, func, bool} from 'prop-types';
import React from 'react';
import {View, Text, Switch} from 'react-native';

import {useTheme} from '../../context/ThemeContext';

Toggleable.propTypes = {
  text: string.isRequired,
  description: string.isRequired,
  onToggle: func,
  value: bool,
};
export default function Toggleable({text, description, onToggle, value}) {
  const {style, values} = useTheme(getThemedStyles);
  return (
    <>
      <View style={style.toggleable}>
        <Text style={style.header}>{text}</Text>
        <Switch
          trackColor={{false: values.FIRST, true: values.GREEN}}
          thumbColor={values.BGFIRST}
          activeThumbColor={values.FIRST}
          onValueChange={onToggle}
          value={value}
        />
      </View>
      <Text style={style.descriptionText}>{description}</Text>
    </>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  descriptionText: {
    fontFamily: 'NotoSans_Regular',
    fontSize: 12,
    color: theme.DARK,
    maxWidth: 360,
    marginBottom: 20,
    mobile: {
      maxWidth: 220,
    },
  },
  header: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 16,
    color: theme.DARK,
  },
  toggleable: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
});
