import './checkbox.css';
import React, {forwardRef} from 'react';

const Checkbox = forwardRef((props, ref) => {
  return (
    <div className="em-checkbox-wrapper">
      <input ref={ref} id="em-checkbox" type="checkbox" {...props} />
    </div>
  );
});

export default Checkbox;
