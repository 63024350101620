import {useIsFocused} from '@react-navigation/native';
import React from 'react';
import {Text, View, LogBox, ActivityIndicator} from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import EtherButton from '../../components/EtherButton';
import IdleTimer from '../../components/IdleTimer';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';

//This line ignores a warning that is irrelevant to our use case.
//SetTimeout won't work if the app is minimized. They advise
//against using long timeouts. This app wont ever be minimized.
LogBox.ignoreLogs(['Setting a timer']);

export default function CheckoutExpress({navigation}) {
  const {clearCart, cartUrl} = useCart();

  const isFocused = useIsFocused();

  function endPurchase() {
    clearCart();
    window.location = localStorage.getItem('kioskUrl');
  }

  function navToOrder() {
    navigation.navigate('Manual Checkout');
  }

  if (cartUrl) console.log('QR Checkout URL: ', cartUrl);
  const {style, values} = useTheme(getThemedStyles);
  return (
    <IdleTimer onTimeout={endPurchase} active={isFocused}>
      <View style={style.mainView}>
        <View style={style.topRow}>
          <Text style={style.textHeader}>Your cart has been created!</Text>
          <Text style={style.subText}>
            Scan the QR code below to finish checkout on your phone!
          </Text>
        </View>
        {cartUrl ? (
          <QRCode
            value={cartUrl}
            quietZone={20}
            size={350}
            color="black"
            backgroundColor={values.BGSECOND}
          />
        ) : (
          <ActivityIndicator size={80} color={values.FIRST} />
        )}
        <View style={style.buttonZone}>
          {/* TODO: Trigger on web cart view, or after timeout when application has been idle */}
          <EtherButton
            style={[style.button, style.allDoneButton]}
            onPress={endPurchase}
          >
            <Text style={style.allDoneText}>All Done!</Text>
          </EtherButton>
          <EtherButton
            style={[style.button, style.orderButton]}
            onPress={navToOrder}
          >
            <Text style={style.nextButtonText}>Text Message/Email</Text>
          </EtherButton>
        </View>
      </View>
    </IdleTimer>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  allDoneButton: {
    backgroundColor: theme.LIGHT,
  },
  allDoneText: {
    color: theme.DARK,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
  },
  button: {
    flex: 1,
    height: 60,
    marginHorizontal: 5,
    borderColor: theme.FIRST,
    borderRadius: 5,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonZone: {
    flexDirection: 'row',
    padding: 20,
    width: 540,
    marginTop: 30,
  },
  disclaimer: {
    color: theme.SECOND,
  },
  mainView: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.BGSECOND,
  },
  nextButtonText: {
    color: theme.LIGHT,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
  },
  orderButton: {
    backgroundColor: theme.SECOND,
  },
  subText: {
    fontStyle: 'italic',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.body,
    color: theme.SECOND,
    textAlign: 'center',
  },
  textHeader: {
    fontFamily: 'NotoSans_Bold',
    fontSize: 50,
    color: theme.FIRST,
    textAlign: 'center',
  },
  topRow: {
    padding: 20,
  },
});
