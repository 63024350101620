import React from 'react';
import {View, Text, Image} from 'react-native';

import EtherButton from './EtherButton';
import EtherInput from './EtherInput';
import {useTheme} from '../context/ThemeContext';
import {formatTimestamp} from '../utils/common/funcs';

export default function StoreAccessCodePrompt({
  storeInfo,
  eventInfo,
  accessCode,
  setAccessCode,
  onConfirm,
}) {
  const {style} = useTheme(getThemedStyles);

  return (
    <View style={style.container}>
      {storeInfo.logo ? (
        <Image
          style={style.userLogo}
          resizeMode="contain"
          source={storeInfo.logo}
        />
      ) : null}
      <Text style={style.subheader}>
        {eventInfo.location} - {formatTimestamp(eventInfo.createdAt)}
      </Text>
      <Text style={style.header}>{eventInfo.name}</Text>
      <Text style={style.accessCodeText}>
        This event is locked
        <br />
        Enter the access code
      </Text>
      <EtherInput
        placeholder="Access Code"
        value={accessCode}
        onChangeText={setAccessCode}
        onSubmitEditing={onConfirm}
        maxLength={32}
        style={style.accessCodeField}
      />
      <EtherButton onPress={onConfirm} style={{marginTop: 20}} text="Confirm" />
    </View>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  accessCodeField: {
    marginTop: 20,
    width: 230,
  },
  accessCodeText: {
    marginTop: 50,
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.bigBody,
    color: theme.DARK,
    maxWidth: 400,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    padding: 20,
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.planetHeader,
    mobile: {
      fontSize: fontSize.header,
    },
  },
  subheader: {
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.header,
    textAlign: 'center',
    mobile: {
      fontSize: fontSize.bigBody,
    },
  },
  userLogo: {
    margin: 20,
    minWidth: 300,
    maxWidth: 600,
    height: 140,
  },
});
