import axios from 'axios';
import React, {useState, useEffect, useContext, useCallback} from 'react';
import toast from 'react-hot-toast';

import {useAdminData} from './AdminContext';
import {clearLocalStorageSessionInfo} from '../utils/common/funcs';
import etherFetch from '../utils/ether-fetch/etherFetch';
import showNetworkError from '../utils/ether-fetch/showNetworkError';

const OperatorContext = React.createContext();

export function OperatorProvider({children}) {
  const [op, setOp] = useState('');
  const [operatorData, setOperatorData] = useState(null);
  const currentRoute = window.location.pathname;
  const {adminData} = useAdminData();

  const changeOperator = useCallback((value) => {
    async function loadUser() {
      return await etherFetch('/auth/me').then((r) => r.json());
    }

    loadUser().then((parsedResponse) => setOperatorData(parsedResponse));
    setOp(value);
  }, []);

  /**
   * Uploads the input file and sets it as the operator's logo
   *
   * @param {string} url - the api endpoint for the upload
   * @param {File} file - the file to be uploaded
   * @param {Object} fields - items to attach as form data
   * @returns Promise<void>
   */
  async function uploadFile(url, file, fields = {}) {
    const formData = new FormData();
    formData.append('uploads', file);
    for (const [key, value] of Object.entries(fields)) {
      formData.append(key, value);
    }
    const {origin} = window.location;
    const uploadRoute = `${origin}${url}`;

    const config = {
      headers: {
        authorization: localStorage.token,
      },
    };

    try {
      const promise = await axios.post(uploadRoute, formData, config);
      changeOperator(operatorData?.username);
      return promise;
    } catch (err) {
      if (err?.response?.status === 413 /* Too large */) {
        toast.error('File is too large. Please try a file smaller than 10MB');
        return;
      }
      showNetworkError(err);
      throw err;
    }
  }

  /**
   * Updates the operator's profile data
   *
   * @param {Object} data - the new operator data
   * @returns {Promise<Response>}
   */
  async function updateProfile(data) {
    const response = await etherFetch('/config/user', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    });

    changeOperator(operatorData?.username);
    return response;
  }

  const identificationComplete = operatorData?.identification?.complete;
  const stripeComplete = adminData?.skipStripe || operatorData?.stripeId;

  const missingInfo = !identificationComplete || !stripeComplete;

  if (op && operatorData && missingInfo && !currentRoute.includes('setup')) {
    window.location.pathname = 'setup';
  }

  // Log in to Operator
  useEffect(() => {
    if (localStorage.token) {
      changeOperator(localStorage.username || null);
    }
  }, [changeOperator]);

  function clearOperator() {
    clearLocalStorageSessionInfo();
    setOp('');
  }

  function getStoreSlug(user) {
    if (!user) user = operatorData;
    return user?.customization?.customUrl || null;
  }

  return (
    <OperatorContext.Provider
      value={{
        op,
        changeOperator,
        clearOperator,
        operatorData,
        setOperatorData,
        uploadFile,
        updateProfile,
        getStoreSlug,
      }}
    >
      {children}
    </OperatorContext.Provider>
  );
}

function useOperator() {
  const context = useContext(OperatorContext);
  if (!context) {
    throw new Error('useOperator must be used within an OperatorProvider');
  }

  return context;
}

export {useOperator};
export default OperatorContext;
