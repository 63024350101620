import toast from 'react-hot-toast';

import etherFetch from '../ether-fetch/etherFetch';

/**
 * Creates and returns a new sign-up code.
 * @async
 * @function
 * @returns {Promise<string|undefined>} A promise that resolves to the created sign-up code.
 * If the request fails, an error toast is displayed and the promise resolves to `undefined`.
 */
export async function createSignUpCode() {
  return etherFetch('/auth/create-sign-up-code', {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((data) => data.code)
    .catch(() => {
      toast.error('Sign up code creation failed');
    });
}
